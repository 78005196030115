import React, { useState } from 'react'
import ChangelogCategory from './ChangelogCategory'
import Link from '@/components/CustomLink'
import { LinkIcon } from '@heroicons/react/solid'
import { useCurrentOrganization } from '../data/organization'
import { useTranslation } from 'next-i18next'
import { IChangelog } from '../interfaces/IChangelog'
import useElementSize from './useElementSize'
import { cn } from '@/lib/utils'
import { ContentModifier } from './ContentReplacer'
import { toast } from 'sonner'
import { formatLocalizedDate } from './MainPostView'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './radix/Tooltip'
import FloatingChangelogComment from './FloatingChangelogComment'
import { KeyedMutator } from 'swr'
import { useAtom } from 'jotai'
import { lastViewedSubmissionAtom } from '@/atoms/displayAtom'
import Zoom from 'react-medium-image-zoom'

const ChangelogEntry: React.FC<{
  title: string
  categories: IChangelog['changelogCategories']
  content: string
  date: string
  id?: string
  widget?: boolean
  disabled?: boolean
  modifiedParsedContent?: any
  featuredImage?: string
  singlePage?: boolean
  nth?: number
  changelog: IChangelog
  mutateChangelogs: KeyedMutator<any>
  commentThreadId?: string
}> = ({
  title,
  categories,
  content,
  date,
  id,
  widget = false,
  disabled = false,
  modifiedParsedContent,
  featuredImage,
  singlePage = false,
  nth,
  changelog,
  commentThreadId,
  mutateChangelogs,
}) => {
  const { t, i18n } = useTranslation()
  const [isShowingMore, setIsShowingMore] = useState(singlePage ? true : false)
  const [editorHeightRef, { width, height: editorHeight }] = useElementSize()
  const [lastViewedSubmission, setLastViewedSubmission] = useAtom(lastViewedSubmissionAtom) // Correctly initializing a ref with an empty string

  const featuredImgStyle = cn('w-full mb-8 rounded-lg', widget && 'mb-6 rounded-none')

  const isFirst = !((nth || 0) < 2)

  const { org } = useCurrentOrganization()

  return (
    <div className={cn(!widget ? 'md:flex relative' : 'flex flex-col-reverse overflow-x-hidden')}>
      <div
        className={cn(
          !widget
            ? 'mb-5 md:mb-0 md:mt-2 w-full text-sm md:w-[200px] flex-shrink-0 md:pr-12'
            : null,
          widget && 'p-4 border-t dashboard-border',
          widget && !(!isShowingMore && editorHeight === 502) && 'mt-4'
        )}
      >
        <div
          className={cn(
            !widget
              ? 'sticky top-6 gap-3 md:flex-col md:items-start flex justify-between items-center'
              : 'flex items-center gap-3 justify-between'
          )}
        >
          <p className="flex items-center text-xs font-medium text-gray-400 capitalize dark:text-foreground">
            {formatLocalizedDate(date, i18n?.language)}
          </p>
          <div className="flex items-center justify-between">
            <div className="flex flex-wrap items-center gap-2">
              {categories &&
                categories?.map((category) => (
                  <div key={category.id}>
                    <ChangelogCategory category={category} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className={'max-w-3xl w-full relative'}>
        {featuredImage ? (
          widget ? (
            <img
              src={
                // `https://featurebase.app/cdn-cgi/image/width=1500,quality=100,format=webp/` +
                featuredImage
              }
              className={cn(featuredImgStyle)}
            />
          ) : (
            <Zoom zoomMargin={12} classDialog="custom-zoom">
              <img
                src={
                  // `https://featurebase.app/cdn-cgi/image/width=1500,quality=100,format=webp/` +
                  featuredImage
                }
                className={cn(featuredImgStyle)}
              />
            </Zoom>
          ) // widget || singlePage ? (
        ) : //   <img src={featuredImage} className={cn(featuredImgStyle)} />
        // ) : (
        //   <Link href={'/changelog/' + changelog?.slug}>
        //     <img
        //       onClick={() => {
        //         setLastViewedSubmission(changelog?.id)
        //       }}
        //       src={featuredImage}
        //       className={cn(featuredImgStyle, 'cursor-pointer')}
        //     />
        //   </Link>
        // )
        null}
        <div
          className={cn(
            'w-full flex justify-between items-center gap-3',
            widget && 'px-4',
            widget && !featuredImage && 'pt-5'
          )}
        >
          <div className="max-w-xl">
            {title ? (
              !widget && !disabled && !singlePage ? (
                <Link href={'/changelog/' + changelog?.slug}>
                  <a
                    onClick={() => {
                      setLastViewedSubmission(changelog?.id)
                    }}
                  >
                    <h2 className="text-xl font-semibold text-gray-600 sm:text-2xl content dark:text-white placeholder:text-lg placeholder:text-gray-50">
                      {title}
                    </h2>
                  </a>
                </Link>
              ) : (
                <h2 className="text-xl font-semibold text-gray-600 sm:text-2xl content dark:text-white placeholder:text-lg placeholder:text-gray-50">
                  {title}
                </h2>
              )
            ) : (
              <h2 className="font-semibold text-gray-600 dark:text-background-accent placeholder:text-lg placeholder:text-gray-50">
                {t('title')}
              </h2>
            )}
          </div>

          {id && (
            <div className="inline-block ml-auto">
              <TooltipProvider>
                <Tooltip delayDuration={300}>
                  <TooltipTrigger asChild>
                    <button
                      onClick={() => {
                        try {
                          navigator.clipboard.writeText(
                            `${window.location.origin}/changelog/${changelog?.slug}`
                          )
                          toast.success('Copied to clipboard')
                        } catch (err) {
                          toast.error('Failed to copy to clipboard')
                        }
                      }}
                      className="p-1 mr-2 bg-transparent shadow-none focus:ring-0 hover:bg-gray-100 hover:dark:bg-secondary"
                    >
                      <LinkIcon className="w-4 h-4 secondary-svg" />
                    </button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="flex items-center justify-center">
                      <p className="text-xs text-gray-400 dark:text-foreground">
                        {t('copy-link-to-clipboard')}
                      </p>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          )}
        </div>

        <div className={cn('relative', widget && ` px-4`)}>
          <div className={cn('changelog relative mt-6', widget && ' -mx-4')}>
            <div
              ref={editorHeightRef}
              className={cn(
                '  installation-content text-gray-500/95 dark:text-foreground ',
                isShowingMore || !isFirst ? '' : 'max-h-[100px] overflow-hidden',
                widget && 'px-4'
              )}
            >
              <ContentModifier
                openReadMore={() => {
                  setIsShowingMore(true)
                }}
                content={modifiedParsedContent}
              />
            </div>
            {!isShowingMore && editorHeight === 100 && isFirst && (
              <button
                onClick={() => setIsShowingMore(true)}
                className={cn('continue-reading-overlay group')}
              >
                <p className="mb-2 text-sm font-medium bg-gray-50/50 dark:bg-border/50 px-2 py-0.5 rounded-md main-transition">
                  {t('continue-reading')}
                </p>
              </button>
            )}
          </div>
          {changelog && !org.settings.disableChangelogCollaboration && (
            <FloatingChangelogComment
              unOpenedChangelog={isFirst}
              mutateChangelogs={mutateChangelogs}
              changelog={changelog}
              commentThreadId={commentThreadId}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ChangelogEntry

import {
  BellIcon,
  CheckIcon,
  FilterIcon,
  SearchIcon,
} from '@heroicons/react/solid'
import { useAtom } from 'jotai'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState, useRef } from 'react'
import { subscribeToChangelog, unsubscribeFromChangelog } from '../../network/lib/changelog'
import { authenitcateModalAtom } from '../atoms/authAtom'
import { useCurrentOrganization, useIsSubscribed } from '../data/organization'
import { IUser } from '../interfaces/IUser'
import { Button } from './radix/Button'
import { XIcon } from 'lucide-react'
import { IChangelogFilters } from '@/interfaces/IChangelog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './radix/DropdownMenu'
import { cn } from '@/lib/utils'
import { toast } from 'sonner'

const useDidMountEffect = (func: any, deps: any) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      func()
    } else {
      didMount.current = true
    }
  }, deps)
}

const ChangelogCTA: React.FC<{
  id: string | undefined
  setFilters: React.Dispatch<React.SetStateAction<IChangelogFilters>>
  filters: IChangelogFilters
  user?: IUser
}> = ({ id, user, setFilters, filters }) => {
  const [authenitcateModal, setAuthenitacteModal] = useAtom(authenitcateModalAtom)
  const { org, mutateCurrentOrg } = useCurrentOrganization()
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState<string>('')

  const { subscribed, mutateIsSubscribed, rawData } = useIsSubscribed(true)

  useEffect(() => {
    if (user) {
      mutateIsSubscribed()
    }
  }, [user])

  const handleSubscribe = () => {
    if (user) {
      mutateIsSubscribed({ ...rawData, subscribed: !subscribed }, false)
      if (subscribed) {
        unsubscribeFromChangelog()
          .then(() => {
            toast.success('Successfully unsubscribed from changelog.')
          })
          .catch(() => {
            toast.error('Error unsubscribing from changelog.')
          })
      } else {
        subscribeToChangelog()
          .then(() => {
            toast.success('Successfully subscribed to changelog.')
          })
          .catch(() => {
            toast.error('Error subscribing to changelog.')
          })
      }
    } else {
      setAuthenitacteModal(true)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFilters((prev) => ({ ...prev, q: searchQuery ? searchQuery : undefined }))
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [searchQuery])

  return (
    <>
      <Button onClick={() => handleSubscribe()} className="text-[13px] h-[33px]">
        {!subscribed ? (
          <BellIcon className="w-4 h-4 mr-1.5" />
        ) : (
          <XIcon className="w-4 h-4 mr-1.5" />
        )}

        {org?.structure?.changelog?.mainChangelogButtonText &&
        org.structure.changelog.mainChangelogButtonText !== 'Changelog'
          ? subscribed
            ? 'Unsubscribe from ' + org?.structure?.changelog?.mainChangelogButtonText
            : 'Subscribe to ' + org?.structure?.changelog?.mainChangelogButtonText
          : subscribed
          ? t('unsubscribe-from-changelog')
          : t('subscribe-to-changelog')}
      </Button>
      <div className="items-end hidden gap-3 sm:flex">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button className="dashboard-secondary shadow-none h-[33px] dark:shadow-none dark:bg-transparent dark:border-border">
              <FilterIcon
                style={{
                  color:
                    filters.changelogCategories && filters.changelogCategories?.length > 0
                      ? org.color
                      : undefined,
                }}
                className={cn('h-4 w-4')}
                aria-hidden="true"
              />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            {filters.changelogCategories && filters.changelogCategories?.length > 0 && (
              <DropdownMenuItem
                onSelect={() => {
                  setFilters((prev) => ({
                    ...prev,
                    changelogCategories: [],
                  }))
                }}
              >
                <XIcon className="mr-1.5 h-4 w-4 secondary-svg" />
                Remove filters
              </DropdownMenuItem>
            )}
            {org.changelogCategories?.map((category, index) => (
              <DropdownMenuItem
                onSelect={() => {
                  setFilters((prev) =>
                    prev.changelogCategories?.includes(category.name)
                      ? {
                          ...prev,
                          changelogCategories: prev.changelogCategories?.filter(
                            (c) => c !== category.name
                          ),
                        }
                      : {
                          ...prev,
                          changelogCategories: prev?.changelogCategories
                            ? [...prev?.changelogCategories, category.name]
                            : [category.name],
                        }
                  )
                }}
                key={category?.id}
              >
                {filters.changelogCategories &&
                  filters.changelogCategories?.includes(category.name) && (
                    <CheckIcon className="mr-1.5 h-4 w-4 secondary-svg" />
                  )}
                {category.name}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        <div>
          <div className="relative mt-2 rounded-md">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <SearchIcon className="w-4 h-4 secondary-svg" aria-hidden="true" />
            </div>
            <input
              onChange={handleChange}
              type="text"
              value={searchQuery}
              className="block w-full rounded-md bg-transparent dark:bg-transparent border py-1.5 pl-9 dark:border-border"
              placeholder="Search entries..."
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangelogCTA
